<template>
  <div id="home">
    <div class="cover-main">
      <div class="header">
        <i class="iconfont icon-title app-title"></i>
        <div class="header-r">
          <!-- <a class="to-web" href="/#/map">WEB版</a> -->
          <a class="about-us" target="_blank" href="/#/about">关于我们</a>
        </div>
      </div>
      <div class="footer">
        <div class="copyright">&copy; 2021</div>
        <div class="company">上海嘤其鸣网络科技有限公司</div>
        <div class="icp">&nbsp;&nbsp;沪ICP备 2021034677号-1</div>
      </div>
      <div class="case-wrapper">
        <div class="phone">
          <div class="phone-video">
            <video autoplay muted loop webkit-playsinline="true" playsinline="true">
              <source src="https://renjianhuaxu.com/download/huaxu.mp4" type="video/mp4">
            </video>
          </div>
          <div class="iphone-case"></div>
        </div>
        <div class="download-wrapper">
          <div class="download-tip">APP下载：</div>
          <div class="app-link">
            <a class="apple app-link-item" href="https://apps.apple.com/app/id6444742923">
              <i class="iconfont icon-apple-fill"></i>
              <span>iOS版</span>
            </a>
            <a class="android app-link-item" href="https://renjianhuaxu.com/download/huaxu.apk">
              <i class="iconfont icon-android-fill"></i>
              <span>安卓版</span>
            </a>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>

export default {
  name: "Home"
}
</script>

<style lang="scss" scoped>
#home {
  width: 100%;
  height: 100%;
  position: relative;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: url(https://renjianhuaxu.com/download/bg-huaxu.jpg);
}
.top-bar {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.cover-main {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1001;
  background-color: rgba(0, 0, 0, 0.6);
}
.case-wrapper {
  position: absolute;
  width: 45rem;
  height: 40rem;
  left: 35%;
  top: 20%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .download-wrapper {
    display: flex;
    flex-direction: column;
    width: 20rem;
    margin-top: -10%;
  }
  .download-tip {
    color: #fff;
    font-size: 1.8rem;
    margin-bottom: 1.5rem;
  }
  .phone {
    width: 20rem;
    height: 40rem;
  }
  .phone-video {
    position: absolute;
    width: 20rem;
    height: 40rem;
    display: flex;
    justify-content: center;
    align-items: center;
    video {
      width: 20rem;
      height: 37rem;
    }
  }
  .iphone-case {
    position: absolute;
    width: 20rem;
    height: 40rem;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url(https://renjianhuaxu.com/download/bg-phone.png);
  }
}
.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1.5rem 0;
  color: #fff;
  &-r {
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }
  .app-title {
    margin-left: 4%;
    font-size: 2rem;
  }
  .to-web, .about-us {
    color: #fff;
    text-decoration: none;
    margin-right: 2%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.footer {
  display: flex;
  justify-content: center;
  padding: 1.5rem 0;
  width: 100%;
  position: absolute;
  bottom: 0;
  z-index: 1001;
  color: rgba(255, 255, 255, 0.7);
}
.app-link {
  display: flex;
  margin-right: 4%;
  &-item {
    height: 2.4rem;
    display: flex;
    align-items: center;
    padding: 0 1.5rem;
    background-color: #fff;
    border-radius: 2.4rem;
    cursor: pointer;
    color: #333;
    text-decoration: none;
  }
  .apple {
    margin-right: 2rem;
  }
  i {
    font-size: 1.8rem;
  }
  span {
    display: inline-block;
    margin-left: 0.4rem;
    font-size: 1.2rem;
  }
}
@media screen and (max-width: 50rem) {
  #home {
    background-position: 40% 50%;
    background-size: auto;
  }
  .header .about-us {
    display: none;
  }
  .app-link {
    &-item {
      span {
        display: none;
      }
    }
  }
  .case-wrapper {
    position: absolute;
    width: 50rem;
    height: 50rem;
    left: 50%;
    margin-left: -25rem;
    top: 10%;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  .download-wrapper {
    align-items: center;
  }
}
</style>>
<style lang="scss">
#home .vm--modal {
  display: flex;
  flex-wrap: wrap;
  background-color: rgba(255, 255, 255, 0.8);
}
</style>